.FormWrapper{
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    column-gap: 1em;
    padding: 25px !important;
//     background: #D9D9D9;
// box-shadow: inset 1px 1px 6px #D9D9D9;
border-radius: 15px;
    // .FormField{
    //     &--TwoCol{
    //         grid-column: 2 / 2 span;
    //     }
    //     &--FullCol{
    //         grid-column: 1 / 3 span;
    //     }
    // }
}

