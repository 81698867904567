.vendor-price-row.ant-form {
    padding: 1em;
    margin: 1em auto;
    .ant-form-item {
        margin-bottom: 0;
    }
}

.cellPadding{
    padding: 4px;
    font-size: 11px;

}

.itemCellPadding{
    padding: 4px;
    font-size: 11px;

}

.priceBox{
    padding: 16px;
    background: aliceblue;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px lightgray;
}

.smallText{
    font-size: 11px;
    font-weight: 500;
}