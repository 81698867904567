.select {
    .countryCode {
        width: 20%;
        .ant-select-selector {
            display: none;
        }
    }
    .number {
        width: 80%;
    }
}
