.vendor {
    border-radius: 15px;
    background: rgba(249, 249, 249, 0.76);
    &:not(:last-child) {
        margin-bottom: 1em;
    }
    .vendorHeader {
        padding: 1.4em;
        border-bottom: 1px #c1c0c0 solid;
        display: flex;
        gap: 1em;
        .vendorName {
            font-weight: bold;
            font-size: 13px;
        }
    }
    .vendorPrices {
        padding: 0.7em;
        .quantityPricesHead {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
        }
        .vendorQuantityPrice {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
        }
    }
}