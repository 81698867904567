.itemDetail{
    display: flex;
    justify-content: space-evenly;
    background-color: #F9F9F9;
    border-radius: 16px;
    padding: 16px;
    align-items: center;
}

.imageBox{

    object-fit: cover;
    border: 1px solid;
    border-radius: 14px;
    
}

.tabBody{
    background: #F9F9F9;
    padding: 32px;
    border-radius: 0px 16px 16px 16px;
}
.rejectTab {
    background: #FCEAE9;
    color: red;
    font-weight: 600;
}