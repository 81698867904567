//@import "./_config.scss";
@primary-color: @primary-color;
@secondary-color: #23b17c;
@gray1: #9b9b9b;
@gray-light: #efefef;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-internal-autofill-selected {
  background-color: white !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#root {
  height: 100%;
}
.outer-wrapper {
  display: grid;
  grid-template-columns: 225px auto;
  height: 100%;
  .page-body {
    display: grid;
    flex: 1;
  }
}

.loading-screen {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: nowrap;
}
.page.login {
  padding: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: @primary-color;
  .ant-input-affix-wrapper {
    border-width: 0;
    border-bottom-width: 1px;
    &:not(.ant-input-affix-wrapper-disabled):hover {
      box-shadow: none;
    }
  }
}

.ant-collapse-header-text {
  & > .anticon {
    color: @primary-color;
  }
  h1 {
    margin-bottom: auto;
    line-height: 1.5rem;
  }
}

.ant-collapse-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
