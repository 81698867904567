.wrapper {
    overflow: hidden;
    display: grid;
    grid-template-columns: minmax(min-content, 2fr) minmax(min-content, 3fr);
    background: white;
    border-radius: var(--radius-large);
    align-items: center;
    align-items: start;
    justify-items: start;
    gap: 2rem;
    padding: var(--space-small);
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }

    .left {
        .image {
            width: 100%;

            img {
                border: 1px var(--color-silver-line) solid;
                border-radius: var(--radius-small);
                max-width: 100%;
                overflow: hidden;
            }
        }
    }

    .right {
        padding-top: var(--space-small);
        background-color: #FAFAFA;
        width: 100%;
        padding-left: 20px;

        .specifications {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: var(--space-small);
            margin: 2rem auto;

            .name {
                color: var(--color-light-black);

                &:after {
                    content: ':';
                }
            }

            .value {
                font-weight: 500;
            }
        }

        .quantities {
            width: 100%;
            text-align: left;
            border-collapse: separate;
            border-spacing: 0 var(--space-small);
            color: var(--color-light-black);

            .quantityHeading {
                color: #000;
            }

            .quantityRow {
                display: grid;
                justify-items: stretch;
                padding: 0.8rem 1.2rem;
                grid-template-columns: repeat(4, minmax(max-content, 1fr));

                &:not(.quantityHeading) {
                    cursor: pointer;
                }

                .quantityCheckbox {
                    text-align: right;
                }

                &:has(input:checked) {
                    box-shadow: 1px 1px 3px var(--color-green);
                    border-radius: var(--radius-small);
                }

                input[type="checkbox"] {
                    cursor: pointer;
                }
            }
        }
    }

}

.item{
    background-color: #EBF2FC !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border-radius: 5px !important;
    width: 200px !important;
    
}
.itemInfo{
    font-size: 20px;
    padding: 6px;
    margin-right: 10px;
}


.footerButton{
    text-align: center;
   
    display: inline-block;
}

.stepBody{
    width: 100%;
    text-align: center;
   background-color: white;
   border-radius: 5px;
   padding: 20px;
}

.cardValue{
    font-weight: 600 !important;
    color: #4178D1;
    font-size: 15px !important;
}

.message{
    text-align: start;
    padding: 16px 32px 16px 32px;
    background: #EBF2FC;
    border-radius: 13px;
    box-shadow: 2px 1px 10px lightgray;
}
.stepBox{
    margin-top: 20px;
    border-radius: 10px;
    border: 0.5px solid black;
    background-color: white;
    padding: 30px;

}

.viewPDF{
    float: right;
    margin-top: 16px;
}

.visualBox{

    text-align: start;
    padding-right: 10px;
    height: 60px;
    background: white;
    margin-bottom: 16px;
    border-radius: 13px;
    box-shadow: 2px 1px 10px lightgray;

}
.activityLogs{
    
    text-align: start;
    padding: 32px;
}

.activity{
    border-radius: 16px;
    background: white;
    padding: 16px;
    box-shadow: 1px 1px 5px lightgray;
    margin-bottom: 8px;
}

.messagebox{
   
}

.toggleButton{
    text-align: start;
    margin: 16px;
}

.ship{
    vertical-align: baseline;
    background: white;
    /* border-radius: 16px; */
    box-shadow: 2px 2px 5px lightgray;
}

.imageBox{
    object-fit: cover;
    border: 1px solid;
    border-radius: 14px;
    margin-left: 64px;
}

.uploadBox{
    padding: 16px;
    background: #FDF4F3;
    border-radius: 16px;
    box-shadow: 0px -1px 10px 4px inset #E7E3E3;
}

.proofBox{
    background-color: aliceblue;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    box-shadow: 0px -1px 10px 4px inset lightblue;
}