.FormWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1em;
    .FormField{
        &--TwoCol{
            grid-column: 2 / 2 span;
        }
        &--FullCol{
            grid-column: 1 / 3 span;
        }
    }
}

.searchHeader{
    display: flex;
    flex-direction: row-reverse;
    margin: 20px;
    justify-content: space-between
  }