.progressSteps {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: center;

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;

        .stepItem {
            background-color: #f2f2f2;
            border-radius: 50%;
            padding: 5px;

            .stepIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding: 7px;

                img {
                    background-color: #8F8F8F;
                }
            }
        }


        &.active {}
    }
}