@import "./../../../styles/_config.scss";
.wrapper {
    .item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: 1px gray solid;
        &:not(:first-child) {
            border-top: none;
        }
        &:hover {
            background-color: $secondary-color;
        }
        &.active {
            background-color: $secondary-color;
            columns: white;
        }
        .quantity {
            padding: 0.8em;
            border-right: 1px $gray1 solid;
        }
        .price {
            padding: 0.8em;
        }
    }
}
