.wrapper {
    margin:1em auto;
    padding-bottom: 1em;
    &:not(:last-of-type) {
        border-bottom: 1px #c1c0c0 solid;
    }
    .header {
        display: grid;
        grid-template-columns: 2em 1fr fit-content(8ch);
        align-items: center;
        color: #0852ca;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 2em;
    }
}
