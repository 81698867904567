.dropArea {
    display: flex;
    border-radius: var(--radius-small);
    border: 1px var(--color-silver-line) solid;
    justify-content: space-between;
    gap: var(--space-large);
    /* padding: var(--space-large); */
    flex-direction: row;
    align-items: center;
    margin: 16px;
    /* padding: 32px; */
    width: 8%;

    .dropText {
        font-size: var(--fontsize-large);
        font-weight: bold;
    }
}

.files {
    display: flex;
    width: 100%;
    align-items: center;
    min-width: 0;
    padding: 16px;
    flex-wrap: wrap;
    background: #f6f6f6;
    align-content: flex-start;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;


    .file {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-content: flex-end;
        align-items: center;

        img {
            display: block;
            width: 80px;
            height: 100px;
            object-fit: cover;
        }
    }
}