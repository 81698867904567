.container {
    background: rgba(246, 246, 246, 0.76);
    padding: 1em;
    border-radius: 15px;
    margin-bottom: 1em;
    &:first-child {
        border-radius: 0 0 15px 15px;
    }
    .vendorDetails {
        width: 100%;
        text-align: left;
        table-layout: fixed;
    }
    .quantityPrices {
        .quantityPricesHead {
            display: grid;
            grid-template-columns: repeat(9, 1fr);
        }
    }
}
.vendorPriceRow {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    background: #f1f1f1;
    border-left: 1px solid #8f8f8f;
}

.selectedVendor{
    background-color: #d4f1d4;
    border-radius: 8px;
    padding: 8px;
}
