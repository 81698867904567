.wrapper {
    display: flex!important;
    flex-direction: row!important;
    justify-content: space-between!important;
    align-items: center!important;
    &::after {
        content: "%";
        padding: 0 11px 0 0;
    }
}
