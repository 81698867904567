$border: 1px #c1c0c0 solid;

.wrapper {
    .item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 1em;
        border: $border;
        border-radius: 10px;
        margin-bottom: 2px;
        &:first-child {
            border-top: $border;
        }
        .itemLabel {
            //border-right: $border;
            padding: 5px 15px;
        }
        .itemValue {
            position: relative;
            color: rgba(0, 0, 0, 0.6);
            .editAction{
                position: absolute;
                right: 10px;
                cursor: pointer;
            }
        }
    }
}