@use '../../css/button';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .content {
        display: grid;
        gap: var(--space-large);
    }
}

.artworksHistory {

    .artworkList {
        display: flex;
        flex-direction: row;

        .artworkListHeader {
            border-bottom: 1px var(--color-silver-line) solid;
        }

        .artwork {
            display: grid;
            grid-template-columns: min-content;
            border: 1px var(--color-silver-line) solid;
            border-radius: var(--radius-small);
            padding: var(--space-xsmall);

            &>img {
                border-radius: var(--radius-small);
                border: 1px var(--color-silver-line) solid;
                width: 100%;
                overflow: hidden;
            }

            .artworkActions {}
        }
    }
}