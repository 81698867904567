@import '~antd/dist/antd.less';

.@{table-prefix-cls} {
    &-sticky {
        &-holder {
            background-color: transparent;
        }
    }
}

.ant-card-body{

    padding: 0px 0px 6px 10px !important;
}
.ant-card-head{

    padding: 0px 0px 0px 10px !important;
}
.ant-card-meta-title{
    margin:0 !important; 
}

.ant-collapse-header-text{
    text-align: start;
}

.ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: transparent !important;
}

.ant-steps-item-container{
    
    align-items: center !important;

}

.ant-steps-item-title{

    position: relative;
    display: inline-block;
 
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
    line-height: 16px;

}
.@{menu-prefix-cls} {
    &-submenu-selected {
        background-color: rgba(255, 150, 150, 0.22);
        color: black
    }

    &-item-selected {
        color: #fff;

        a,
        a:hover {
            font-weight: bold;
            color: #fff;
        }
    }
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    
    color: #37B27C;
    font-weight: 600;
    font-size: 16px;
    text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab{
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}
.ant-tabs-tab-btn{
    position: relative;
    display: inline-flex;
    /* align-items: revert; */
    padding: 12px ;
    width: 200px;
    font-size: 14px;
    background: #E8F8EE;
    border-radius: 11px 11px 0px 0px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.@{input-prefix-cls} {
    &-group-compact {
        .ant-form-item {
            margin-top: 16px;
            &:first-child {

                .@{ant-prefix}-input-affix-wrapper,
                .@{input-prefix-cls},
                .@{select-prefix-cls}-selector {
                    border-radius: @control-border-radius 0 0 @control-border-radius;
                }
            }

            &:last-child {

                .@{ant-prefix}-input-affix-wrapper,
                .@{input-prefix-cls},
                .@{select-prefix-cls}-selector {
                    border-radius: 0 @control-border-radius @control-border-radius 0;
                }
            }
        }
    }
}

.@{radio-prefix-cls} {
    &-group-solid {
        .@{radio-prefix-cls}-button-wrapper {
            &-checked:not(&-disabled) {
                background: @btn-primary-bg;
                border-color: @btn-primary-bg;
            }
        }
    }
}

.ant-radio-button-wrapper-disabled:first-child{
    background: #23B27C;
    border-color: #23B27C;
}
.@{layout-prefix-cls} {
    &-sider {
        background: #FFFFFF;
        box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 0px 40px 40px 0px;
    }
}

.@{collapse-prefix-cls} {
    margin-bottom: 20px;

    &-header {
        &-text {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.@{dialog-prefix-cls} {
    &-title {}

    &-content {}

    &-close {
        &-x {}
        &:focus,
        &:hover {
            color: @icon-color-hover;
            text-decoration: none;
        }
    }

    &-header {
        text-align: center;
    }

    &-body {}

    &-footer {
        text-align: center;
    }

    &-open {}
}

.site-page-header-ghost-wrapper {
    margin-left: -40px;
    padding-left: 40px;
    background: white;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
    width: 96%;
    border-radius: 10px;
    margin-left: 5px;
}
.ant-menu-inline .ant-menu-submenu{
    width: 96%;
    border-radius: 10px;
    margin-left: 5px;
}
.ant-btn-primary {
    background-color: #23B27C;
    border-color: #23B27C ;
}
.ant-btn-cancel{
   
    background-color: #FA5359;
    border-color: #FA5359 ;
    color : #efefef
}
.ant-btn-cancel:hover{
   
    background-color: #f3383f;
    border-color: #f3383f;
    color : #efefef
}

// .ant-input{

// background: #FFFFFF;
// border: 0.75px solid #C1C0C0;
// border-radius: 10px;
// }

