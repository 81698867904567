
.FormWrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1em;
    // .FormField{
    //     &--TwoCol{
    //         grid-column: 2 / 2 span;
    //     }
    //     &--FullCol{
    //         grid-column: 1 / 3 span;
    //     }
    // }
}
.RoleButton {
   
    color: #C054FF !important ;
    font-weight: 600 !important ;
    border: #C054FF !important ;
    width:25% !important;
}

.VendorSmallSection {

    border-radius: 0px 10px 10px 0px !important;
    border-left: 3px #C054FF solid !important;
    margin: 1em 0 1em 0 !important;
    position: relative !important;
    background: #F4F6F8 !important;
	
}