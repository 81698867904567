.box {
  padding: var(--space-large);
  border-radius: var(--radius-large);
  
  &--small {
    @extend .box;
    border-radius: var(--radius-small);
    padding: var(--space-small);
  }

  &--large {
    @extend .box;
    padding: var(--space-large) var(--space-xxlarge);
  }

  &--plain {
    @extend .box;
    border: 1px solid var(--color-silver-line)
  }

  &--white {
    @extend .box;
    background-color: white;
  }

  &--gray {
    @extend .box;
    background-color: var(--color-gray-1);
  }

  &--dark-gray {
    @extend .box;
    background-color: var(--color-gray-4);
  }

  &--light-gray {
    @extend .box;
    background: var(--color-gray-2);
  }

  &--green {
    @extend .box;
    background: var(--color-green);
  }

  &--blue-light {
    @extend .box;
    background-color: var(--color-blue-polar);
  }
}