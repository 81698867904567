.site-page-header-ghost-wrapper {
  .header{
    background-color: white;
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  .action-area {
    display: flex;
    gap:20px;
    flex-flow:row;
    align-items:center;
    .notifications {
      font-size: 20px;
    }
    .filters {
      color: #23B27C;
      border-color: #23B27C;
      background-color: transparent;
    }
  }
}