.FormWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1em;
    .FormField {
        &--col1 {
            grid-column: 2 / 2 span;
        }
        &--col2 {
            grid-column: 2 / 2 span;
        }
        &--col3 {
            grid-column: 1 / 3 span;
        }
    }
}
.searchHeader{
    display: flex;
    flex-direction: row-reverse;
    margin: 20px;
    justify-content: space-between
  }