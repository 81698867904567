.kanaban-wrapper {
    // display: grid;
    // gap: 0.5em;
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    overflow: scroll;
    width: 100%;
    height: 100%;
    display: -webkit-box;

    .kanaban-column {
        background: #f3f3f3;
        border-radius: 15px;
        padding: 0.6em;
        display: flex;
        flex-direction: column;
        width: 24%;
        height: 100%;

        .vendor-kanaban-colum {
            padding: 19px 10px 19px 10px;
          


            font-size: 20px;
            font-weight: 500;
            background-color: white;
            border-radius: 6px;
            box-shadow: -5px 2px 20px 2px lightgrey;
        }
        .production-kanaban-colum{
            padding: 19px 10px 19px 10px;


            font-size: 20px;
            font-weight: 500;
            background-color: white;
            border-radius: 6px;
            box-shadow: -5px 2px 20px 2px lightgrey;
            
        }

        &--list {
            background-color: #f3f3f3;
            border-radius: 10px;
            flex: 1 1 auto;
            padding: 0.6rem;


            .kanaban-card {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                background-color: white;
                border-radius: 7px;
                padding: 0.8em 0;
                box-shadow: -5px 2px 20px 2px lightgrey;

                &:not(:last-child) {
                    margin-bottom: 0.8em;
                }

                &--header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0 0.8em 0;

                    .deadline-delivery-data {
                        color: #d14141;
                        background-color: #fcdfdf;
                        font-weight: 600;
                        font-size: 1em;
                        padding: 5px;
                        border-radius: 5px;
                    }



                }

                &--body {
                    padding: 0 0.8em 0;

                    .customer-name {
                        font-size: 1em;
                    }
                }

                &--footer {
                    .order-id {
                        color: grey;
                        font-weight: 500;
                    }

                    .recieved-date {
                        color: grey;
                        float: right;
                        font-weight: 500;
                    }

                    padding: 8px 0.8em 0;
                    margin-top: 12px;
                    border-top: 0.5px solid #d9d9d9;
                }
            }
        }
    }
}