.actions {
    &:has(.button) {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
    }

    &--expand {
        &:has(.button) {
            @extend .actions;

            .button {
                flex-grow: 1;
            }
        }
    }

    &--compact {
        &:has(.button) {
            @extend .actions;
            gap: 0;
        }

        .button {
            font-size: var(--fontsize-normal);
            font-weight: normal;
            text-transform: none;

            &:hover {
                background-color: var(--color-gray-2);
            }

            &:first-child {
                border-radius: var(--radius-small) 0 0 var(--radius-small);
                border-right: 0;
            }

            &:last-child {
                border-radius: 0 var(--radius-small) var(--radius-small) 0;
                border-left: 0;
            }

            &:not(:first-child, :last-child) {
                border-radius: 0;
            }
        }
    }

    &.align--left {
        justify-content: left;
    }

    &.align--right {
        justify-content: right;
    }

}

.button {
    display: inline-flex;
    border: 1px solid var(--color-light-black);
    color: var(--color-light-black);
    cursor: pointer;
    border-radius: var(--radius-small);
    background: none;
    box-sizing: border-box;
    line-height: 2em;
    padding: 0 2em;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
    }

    &:not(:disabled) {
        &:hover {
            filter: brightness(85%);
        }

        &:active {
            filter: brightness(115%);
        }

        &:focus {
            border-width: 1.5px;
        }
    }

    &--large {
        @extend .button;
        font-weight: 500;
    }

    &--plain {
        @extend .button;
        border: none;
        background: none;
        padding: var(--space-small)
    }

    &--primary {
        @extend .button;
        border: 1px solid var(--color-green);
        color: var(--color-green);
        background: var(--color-light-green);
    }

    &--danger {
        @extend .button;
        border: 1px solid var(--color-red);
        color: var(--color-red);
        background: var(--color-pink);
    }
}