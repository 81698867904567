.wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .item{
        display: flex;
        justify-content: flex-start;
        gap: 1em;
        .itemLabel{
            font-weight: bold;
            &:after{
                content: ':'
            }
        }
    }

}