.kanaban-wrapper {
    display: grid;
    gap: 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    .kanaban-column {
        background: white;
        border-radius: 15px;
        padding: 0.6em;
        display: flex;
        flex-direction: column;
        .kanaban-colum-inside {
            padding: 19px 10px 19px 10px;
            border-left: 5px solid #4178D1;


            font-size: 20px;
            font-weight: 500;
            background-color: white;
            border-radius: 6px;
            box-shadow: -5px 2px 20px 2px lightgrey;
        }
        &--list {
            background-color: #e8f2fb;
            border-radius: 10px;
            flex: 1 1 auto;
            padding: 0.6rem;
            .kanaban-card {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                background-color: white;
                border-radius: 7px;
                padding: 0.8em 0;
                &:not(:last-child) {
                    margin-bottom: 0.8em;
                }
                &--header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0 0.8em 0;
                    .project-number {
                        color: #4178d1;
                        font-weight: 600;
                        font-size: 1em;
                    }
                    .project-source {
                        .anticon {
                            font-size: 1em;
                        }
                    }
                    .project-country {
                        img {
                            height: 1.25em;
                            width: auto;
                        }
                    }
                }
                &--body {
                    padding: 0 0.8em 0;
                    .customer-name {
                        font-size: 1em;
                    }
                }
                &--footer {
                    padding: 0 0.8em 0;
                    border-top: 0.5px solid #d9d9d9;
                }
            }
        }
    }
}
